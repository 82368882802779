import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import swal from 'sweetalert2';
import ReactGA from 'react-ga';

import Barra from '../../imgs/barra-yellow.png';
import Impressora from '../../imgs/impressora.png';
import Sifrao from '../../imgs/sifrao.svg';
import DetalhesDivida from '../../components/detalhesDivida/DetalhesDivida';
import IconeWhatsapp from '../../components/icone-whatsapp/icone-whatsapp'

import BoletoActions from '../../actions/boletoActions';

import './telaInicial.css';

export default function TelaInicial() {
	const boleto = useSelector(state => state.boletoReducer);
	const estado = useSelector(state => state.estadoReducer);
	const [incheckedBoleto, setIncheckedBoleto] = useState(false);
	const [incheckedTerms, setIncheckedTerms] = useState(false);
	const [openDetalhes, setOpenDetalhes] = useState(false);
	const [enabled, setEnabled] = useState(false);
	const dispatch = useDispatch();
	const boletoActions = new BoletoActions(dispatch, estado, boleto);
	let i = 0;

	useEffect(() => {
		if (boleto.textParcela) {
			swal.fire({
				type: 'warning',
				title: 'Parcelas',
				text: boleto.textParcela,
				confirmButtonColor: '#FAB800'
			});
		}
		segundaVia();
		dispatch({ type: 'SET_LOADING_PAGE', payload: false });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [boleto]);

	const checkBoleto = (i) => {
		setIncheckedBoleto(!incheckedBoleto);
		if (incheckedBoleto === false) {
			if (!boleto.possuiTermos || (boleto.possuiTermos && document.getElementById('terms').checked)) {
				document.getElementById('gerarBoleto').style.backgroundColor = '#FAB628';
				setEnabled(true);
			}
		} else if (incheckedBoleto === true) {
			setEnabled(false);
			document.getElementById('gerarBoleto').style.backgroundColor = '#737372';
		}
	};

	const checkTerms = () => {
		setIncheckedTerms(!incheckedTerms);
		if (incheckedTerms === false) {
			if (document.getElementById('horns').checked) {
				document.getElementById('gerarBoleto').style.backgroundColor = '#FAB628';
				setEnabled(true);
			}
		} else if (incheckedTerms === true) {
			setEnabled(false);
			document.getElementById('gerarBoleto').style.backgroundColor = '#737372';
		}
	};

	const SEGUNDA_VIA = 'Segunda Via';
	const TITULO_TERMOS = 'TERMO DE ACEITE - CONFISSÃO E RENEGOCIAÇÃO DE DÍVIDA';
	const DESC_TERMOS = `<span style="text-align:justify">
						<p>O(A) CLIENTE está ciente de que o presente TERMO é ELETRÔNICO e possibilita a renegociação de seu contrato em atraso, o qual não constitui novação do(s) contrato(s) firmado(s) pelas partes, tendo sido pactuado por mera liberalidade do CREDOR.</p><br />
						<p>Sendo assim, o(a) CLIENTE ao clicar no campo "ACEITO" declara estar ciente dos termos da renegociação proposta, e declara expressamente ter anuído com os valores e encargos cobrados, com o parcelamento gerado pelo sistema, bem assim com o valor total a ser pago.</p><br />
						<p>O(A) CLIENTE declara, ainda, ter total ciência de que o presente acordo poderá ser cancelado, independentemente de qualquer aviso prévio, e a dívida original ser exigível de imediato para pagamento, nos valores originariamente contratados (deduzindo-se os valores pagos), caso o(a) CLIENTE descumpra qualquer uma das obrigações aqui assumidas.</p>
						</span>`
	

	const termos = () => {
		swal.fire({
			title: TITULO_TERMOS,
			html: DESC_TERMOS,
			confirmButtonColor: '#FAB800'
	})};
	
	const handleVencimentoChange = event => {
		boletoActions.getDadosVencimento(event.target.value);
	};

	const handleParcelaChange = event => {
		// const parcelaInt = parseInt(event.target.value);
			let parcelaString = event.target.value.split(' ');
			
			let parcelaStringg  = parcelaString[0].slice(0, -1);
	
			let textParcela = `${parcelaString[1]} ${parcelaString[2]}`;
			
			const parcela = _.chain(boleto.parcelas)
				.filter(p => p.numero === parseInt(parcelaStringg) && p.valorParcela === textParcela)
				.head()
				.value();
			if (parcela) {
				dispatch({ type: 'SET_PARCELA', payload: parcela });
		}
	};

	const gerarBoleto = () => {
		ReactGA.event({
			category: 'Click',
			action: 'Gerar Boleto'
		});
		
		if (!!boleto.mensagem) {
			swal.fire({
				type: 'warning',
				title: 'Atenção',
				text: boleto.mensagem,
				confirmButtonColor: '#FAB800'
			}).then(() => {
				dispatch({ type: 'SET_TELA_INICIAL', payload: false });
				boletoActions.emitirBoleto();
			});
		} else {
			dispatch({ type: 'SET_TELA_INICIAL', payload: false });
			boletoActions.emitirBoleto();
		}
	};

	function isEmpty(obj) {
		for(var prop in obj) {
			if(obj.hasOwnProperty(prop)) {
			return false;
			}
		}
		
		return JSON.stringify(obj) === JSON.stringify({});
	}

	const segundaVia = () => {
		if (boleto.parcelasGeradas && !isEmpty(boleto.parcelasGeradas)) {
			let msg_parcela = "";
			for (const parcela of boleto.parcelasGeradas) {
				msg_parcela += `<div class="segunda-via">
					<div>
						<div class="flex">
							<div>
							<label class="bold">Valor</label>
							<p>${parcela.valor}</p>
							</div>
							<div>
							<label class="bold">Vencimento</label>
							<p>${parcela.vencimento}</p>
							</div>
						</div>
					</div>
					<div>
					<p class="linha-segunda-via">${parcela.linhaDig}</p>
					<a href="${parcela.linkPDF}" target="_blank" class="bt-pdf">Visualizar 2ª Via</a>
				</div>
				</div>`
			}
			swal.fire({
				title: SEGUNDA_VIA,
				html: msg_parcela,
				confirmButtonColor: '#FAB800',
				background: '#fff'
			})
		}
	};

	return (
		<div className='Container-inicial'>
			<div>
				{boleto.nuWhatsapp === false ? '' : <IconeWhatsapp numero={boleto.nuWhatsapp}></IconeWhatsapp>}
				<h1 className='Cabecalho-white'>Não espere, pague seu boleto agora!</h1>

				<div className='wholePaper' id='telaInicial'>
					<div className='PapelTop'>
						<img alt='impressora' src={Impressora} className='impressora' />
						<div className='tela-inicial-text-boleto'>
							Boleto
							<br />
							Digital
						</div>
						<div className='LinhaUm'>
							<div className='tela-inicial-boleto-esquerda'>
								<div className='Title2'>
									<br />À VISTA
									<br />
								</div>
								<p className='subTitleSpace2'>{boleto.aVista}</p>
								<div className='Title'>Nome</div>
								<div className='Texto'>{boleto.nome}</div>
								<div className='TitleSpace'>Nº Contrato</div>
								<div className='Texto'>{boleto.NContrato}</div>
							</div>
							<div className='tela-inicial-sifrao'>
								<img alt='Sifrao' src={Sifrao} />
							</div>
							<div className='tela-inicial-boleto-direita'>
								<div className='Title1'>
									{1 === boleto.numParcela ? 'DÉBITO' : 'TOTAL DAS'}
									<br />
									{1 === boleto.numParcela ? 'CORRIGIDO' : 'PARCELAS'}
								</div>
								<p className='subTitleSpace'>{boleto.debitoCorr}</p>
								<div className='Title'>
									Beneficiário<div className='Texto'>{boleto.beneficiario}</div>
								</div>
								<div className='TitleSpace'>
									Vencimento<div className='Texto'>{boleto.vencimento}</div>
								</div>
							</div>
						</div>
						<div className='tela-inicial-selects'>
							<div className='tela-inicial-parcelas'>
								<div className='Title3'>Nº de parcelas:</div>
								<select value={boleto.parcela} onChange={handleParcelaChange} className='select-parcela'>
									{boleto.parcelas.map(i => (
										<option key={i.numero} value={i.text}>
											{i.text}
										</option>
									))}
								</select>
							</div>
							<div className='tela-inicial-vencimentos'>
								<div className='Title3'>Vencimentos:</div>
								<select
									value={boleto.vencimento}
									onChange={handleVencimentoChange}
									className='select-vencimento'>
									{boleto.vencimentos.map(i => (
										<option className='select-vencimento-option' key={i.value} value={i.value}>
											{i.text}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
					{boleto.isSMS ? (
						<div className='labelSMS'>Gere o boleto para receber a linha digitável por SMS:</div>
					) : (
						<div className='PapelBottom'>
							<div className='Linha'>
								<img className='Boleto' alt='boleto' src={Barra} />
								<div className='itensBottom'>
									<div className='TitleBottom2'>Linha Digitável</div>
									<p className='textoBottom'>XXXXX.XXXXX XXXXX.XXXXXX XXXXX.XXXXXX X XXXXXXXXXXXXXX</p>
									<button className='Button2'>Copiar linha digitável</button>
								</div>
							</div>
						</div>
					)}
					<div className='checkbox'>
						<input type='checkbox' checked={incheckedBoleto} id='horns' name='horns' onChange={checkBoleto} />
						<label htmlFor='horns' style={{ whiteSpace: 'nowrap' }}>
							Li e concordo com o boleto
						</label>
					</div>
					{boleto.possuiTermos ? 
						<div className='checkbox'>
							<div>
								<input type='checkbox' checked={incheckedTerms} id='terms' name='terms' onChange={checkTerms} />
								<label htmlFor='terms' style={{ whiteSpace: 'nowrap' }}>
									Li e concordo com o </label>
								<span style={{ color: 'blue', cursor: "pointer" }} onClick={termos}>Termo de Aceite</span>
								
							</div>
						</div>				
					: null }
					<button className='botao-gerar-boleto' disabled={!enabled} id='gerarBoleto' onClick={() => gerarBoleto()}>
						Gerar Boleto
					</button>
					{boleto.detalhes && !isEmpty(boleto.detalhes) ? (
						<button className='Button-detalhes' id='openDetalhe' onClick={() => setOpenDetalhes(!openDetalhes)}>
							Detalhes do acordo
						</button>
					) : null}
					{boleto.parcelasGeradas && !isEmpty(boleto.parcelasGeradas) ? (
						<button className='Button-detalhes' id='segundaVia' onClick={segundaVia}>
							Segunda Via
						</button>
					) : null}
				</div>	
			</div>
			{openDetalhes ? (
				<DetalhesDivida detalhes={boleto.detalhes} onClose={() => setOpenDetalhes(!openDetalhes)} />
			) : null}
		</div>
	);
}
