import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga';

import './telaCPF.css';

import Barra from '../../imgs/barra-title.svg';
import Flex from '../../imgs/powered.svg';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Warning from '../../imgs/warning.svg';

import BoletoActions from '../../actions/boletoActions';
import CPFService from '../../services/cpf';
import HistoricoService from '../../services/historico';

export default function TelaCPF() {
	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const boleto = useSelector(state => state.boletoReducer);
	const usuario = useSelector(state => state.usuarioReducer);
	const estado = useSelector(state => state.estadoReducer);
	const maxLength = usuario.tipo === 'cpf' ? 3 : 2;
	const mask = usuario.tipo === 'cpf' ? `.XXX.${usuario.cpfCnpj}-XX` : `.XXX.${usuario.cpfCnpj}/XXXX-XX`;
	const placeholder = usuario.tipo === 'cpf' ? '_ _ _' : '_ _';
	const historicoService = new HistoricoService(estado);
	const cpfService = new CPFService(estado);
	const boletoActions = new BoletoActions(dispatch, estado, boleto);

	useEffect(() => {
		dispatch({ type: 'SET_LOADING_PAGE', payload: false });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usuario]);

	const confirmarCPF = async e => {
		e.preventDefault();
		setDisabled(true);
		dispatch({ type: 'SET_LOADING_PAGE', payload: true });
		ReactGA.event({
			category: 'Click',
			action: 'Confirmar CPF'
		});
		if ((usuario.tipo === 'cpf' && usuario.digitos.length === 3) || (usuario.tipo === 'cnpj' && usuario.digitos.length === 2)) {
			cpfService
				.validateCPF(usuario.digitos)
				.then(async data => {
					if (data.success) {
						dispatch({ type: 'SET_TOKEN', payload: data.result.token });
						boletoActions.getDadosBoleto(data.result.token);
					} else {
						dispatch({ type: 'SET_LOADING_PAGE', payload: false });
						dispatch({
							type: 'SET_ERROR_MODAL',
							payload: `Erro na validação do seu ${usuario.tipo.toUpperCase()}`
						});
					}
				})
				.catch(err => {
					dispatch({ type: 'SET_LOADING_PAGE', payload: false });
					dispatch({
						type: 'SET_ERROR_MODAL',
						payload: `Erro na validação do seu ${usuario.tipo.toUpperCase()}`
					});
				});
		} else {
			dispatch({ type: 'SET_ERROR_MODAL', payload: `${usuario.tipo.toUpperCase()} Incorreto` });
			dispatch({ type: 'SET_LOADING_PAGE', payload: false });
		}
		setDisabled(false);
	};

	const renderStringCPFCNPJ = () => {
		if (usuario.tipo === 'cpf') {
			return (
				<div>
					Olá, {usuario.nome.split(' ')[0]}! <br /> É você?
				</div>
			);
		} else {
			return (
				<div>
					Olá! Você é responsável por: <br /> {usuario.nomecompleto}?{' '}
				</div>
			);
		}
	};

	const naoConhece = () => {
		historicoService.informaDesconhece(data => {
			setOpenModal(!openModal);
			if (data.success) {
				dispatch({ type: 'SET_CONFIRMA_CPF', payload: false });
				dispatch({ type: 'SET_TELA_DESCONHECE' });
			} else {
				dispatch({
					type: 'SET_ERROR_MODAL',
					payload: 'Erro em nossos servidores, por favor tentar mais tarde! :('
				});
			}
		});
	};

	return (
		<div className='container-tela-cpf'>
			<div className='container-tela-cpf'>
				<div className='container-tela-cpf-items'>
					<div className='container-tela-cpf-img'>
						<img className='barra-title' src={Barra} alt='barraTitle' />
					</div>
					<div className='container-tela-cpf-forms'>
						<div className='container-tela-cpf-text'>
							<h1
								className='cabecalho-telacpf'
								style={{ fontSize: usuario.tipo === 'cpf' ? '2.2em' : '1.9em' }}>
								{renderStringCPFCNPJ()}
							</h1>
							<p className='textoCPF'>
								Para sua segurança, informe os {maxLength} primeiros dígitos do seu{' '}
								{usuario.tipo.toUpperCase()} para autenticar o seu acesso e exibir seu boleto
							</p>
						</div>
						<form className='form-telacpf'>
							<div>
								<input
									type='text'
									maxLength={maxLength}
									className={usuario.tipo}
									placeholder={placeholder}
									value={usuario.digitos}
									onChange={e => dispatch({ type: 'SET_DIGITOS', payload: e.target.value.replace(/[^0-9]/, '') }) }
								/>
								<label className={'label-' + usuario.tipo}>{mask}</label>
							</div>
							<button
								className='tela-cpf-acessar'
								type='submit'
								disabled={disabled}
								onClick={confirmarCPF}>
								Acessar
							</button>
						</form>
						<button className='tela-cpf-nao-conheco' onClick={() => setOpenModal(!openModal)}>
							Não conheço o cliente
						</button>
					</div>
				</div>
			</div>
			<div className='container-tela-cpf-footer'>
				<img src={Flex} alt='poweredFlex' />
			</div>
			<Dialog open={openModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
				<div className='ajustModal'>
					<div className='center-center'>
						<img src={Warning} style={{ width: '15%', padding: '5%' }} alt='warning' />
					</div>
					<DialogTitle className='center-center' id='alert-dialog-title'>
						<div className='fs-35'>Atenção</div>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							className='center-center'
							id='alert-dialog-description'
							style={{ whiteSpace: 'nowrap' }}>
							Confirma que não conhece o cliente?
						</DialogContentText>
					</DialogContent>
					<DialogActions className='center-center'>
						<button className='Button-cpf' onClick={naoConhece}>
							<b>Sim</b>
						</button>
						<button className='Button-cpf' onClick={() => setOpenModal(!openModal)}>
							<b>Não</b>
						</button>
					</DialogActions>
				</div>
			</Dialog>
		</div>
	);
}
