import React from 'react';
import Whatsapp from '../../imgs/wpp.png';

import './icone-whatsapp.css';

const iconeWhatsapp = props =>{
    let href = "https://wa.me/"+props.numero+"?text=Olá";
    return (
        
            <a href={href} target="_blank" rel="noopener noreferrer">
                <img className='whatsapp' alt='Whatsapp' src={Whatsapp} />
            </a>
        
    );
};

export default iconeWhatsapp;